import { graphql, Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React, { useState } from "react"
import Seo from "../components/seo"
import NewLayout from "../components/new_layout"
import rehypeRaw from "rehype-raw"
import ReactMarkdown from "react-markdown"
import Card from "../components/v2023/UI/Card/card"
import Opinions from "../components/v2023/Opinions/opinions"
import ReadyToTalk from "../components/v2023/ReadyToTalk/ready-to-talk"
import TecalisAdapts from "../components/v2023/TecalisAdapts/tecalis-adapts"
import CustomerLogos from "../components/v2023/CustomerLogos/customer-logos"


const RpaAutomatization = ({ data, pageContext }) => {
    const page = data.page.edges[0].node
    const lang = pageContext.langKey
    const helpers = data.helpers.frontmatter
    const [showHowWeDoItem, setShowHowWeDoItem] = useState(-1)

    const selectShowHowWeDoItem = (index) => {
        if (showHowWeDoItem === index) {
            setShowHowWeDoItem(-1)
        } else {
            setShowHowWeDoItem(index)
        }
    }

    return (
        <NewLayout pageContext={pageContext}>
            <Seo
                lang={pageContext.langKey}
                title={page.seo.title}
                description={page.seo.meta_description}
                translates={pageContext.translates}
                image={page.seo?.image_2?.localFile?.publicURL}
            />

            <main className="main">
                <div className="main__section main__section--50 main__section--50--lg color-section purple-dark m-b-80">
                    <div className="container">
                        <div className="container__50">
                            <div className="tag tag--highlight">{page.name}</div>
                            <h1 className="merriweather">{page.hero_title}</h1>
                            <div className="container__text">
                                <ReactMarkdown children={page.hero_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <div className="button__wrapper">
                                <Link to={helpers.request_demo_path}>
                                    <button className="button button--ghost button--xl">{page.hero_button}</button>
                                </Link>
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <div className="gif">
                                <lottie-player src="/animations/automation.json" background="transparent" speed="1" loop autoplay />
                            </div>
                        </div>
                    </div>
                </div>
                <CustomerLogos />
                <div className="main__section main__section--100">
                    <div className="container">
                        <div className="container__text">
                            <ReactMarkdown children={page.info_description} rehypePlugins={[rehypeRaw]} />
                        </div>
                        <div className="grid-lg-3">
                            <div className="image">
                                <GatsbyImage alt={page.info_items_global_image.alternativeText} image={getImage(page.info_items_global_image.localFile)} />
                            </div>
                            {page.info_items.map((item, index) => (
                                <Card
                                    key={index}
                                    name={item.title}
                                    image={item.image_new}
                                    alternativeText={item.image_new.alternativeText}
                                    description={`${item.short_description}${item.long_description}`}
                                />
                            ))}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50  main__section--50-invert main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.adaptation_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.adaptation_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.adaptation_image.alternativeText} image={getImage(page.adaptation_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--md color-section purple">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.automation_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.automation_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50" data-aos="fade-left">
                            <div className="item-list">
                                {page.automation_items.map((item, index) => {
                                    return <div className={`item ${showHowWeDoItem === index ? "item--active" : ""}`} key={index}>
                                        <div className="item__header" onClick={() => selectShowHowWeDoItem(index)}>
                                            <div className="item__header__title">{item.title}</div>
                                            <i className="icon-chevron-down"></i>
                                        </div>
                                        <div className="item__body">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.bpm_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.bpm_description_one} rehypePlugins={[rehypeRaw]} />
                            </div>
                            <div className="tile">
                                <div className="tile__body">
                                    <ReactMarkdown children={page.bpm_description_two} rehypePlugins={[rehypeRaw]} />
                                </div>
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.bpm_image.alternativeText} image={getImage(page.bpm_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <div className="main__section color-section gray">
                    <div className="container">
                        <div className="grid-lg-3-h">
                            {page.bpm_items.map((item, index) => {
                                const animation = { "data-aos": "zoom-in", "data-aos-delay": 200 * index }
                                return <div className="list-highlight" key={index} {...animation}>
                                    <div className="list-highlight__number">{item.order}</div>
                                    <div className="list-highlight__info">
                                        <h4>{item.title}</h4>
                                        <div className="list-highlight__info__text">
                                            <ReactMarkdown children={item.description} rehypePlugins={[rehypeRaw]} />
                                        </div>
                                    </div>
                                </div>
                            })}
                        </div>
                    </div>
                </div>
                <div className="main__section main__section--50 main__section--50-invert main__section--50--sm">
                    <div className="container">
                        <div className="container__50">
                            <h2>{page.regtech_title}</h2>
                            <div className="container__text">
                                <ReactMarkdown children={page.regtech_description} rehypePlugins={[rehypeRaw]} />
                            </div>
                        </div>
                        <div className="container__50">
                            <GatsbyImage alt={page.regtech_image_new.alternativeText} image={getImage(page.regtech_image_new.localFile)} />
                        </div>
                    </div>
                </div>
                <Opinions lang={lang} />
                <TecalisAdapts lang={lang} />
                <ReadyToTalk lang={lang} />
            </main>
        </NewLayout>
    )
}

export default RpaAutomatization

export const rpaQuery = graphql`
    query ($langKey: String) {
        page: allStrapi2022Rpa ( filter: { locale: { eq: $langKey } } ) {
            edges {
                node {
                    seo {
                        title
                        meta_description
                        image_2 {
                            localFile {
                                publicURL

                            }
                        }
                    }
                    name
                    hero_title
                    hero_description
                    hero_button
                    hero_anim_desktop
                    hero_anim_mobile
                    info_description
                    info_items_global_image{
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    info_items {
                        order
                        title
                        short_description
                        long_description
                        image {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                        image_new {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    adaptation_title
                    adaptation_description
                    adaptation_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    adaptation_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    automation_title
                    automation_description
                    automation_items {
                        order
                        title
                        description
                        icon {
                            name
                            alternativeText
                            localFile {
                                publicURL
                            }
                        }
                    }
                    bpm_title
                    bpm_description_one
                    bpm_description_two
                    bpm_image {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    bpm_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                    bpm_items {
                        order
                        title
                        description
                    }
                    regtech_title
                    regtech_description
                    regtech_image_new {
                        name
                        alternativeText
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    width: 608
                                    placeholder: BLURRED
                                    formats: [WEBP]
                                )
                            }
                        }
                    }
                }
            }
        }
        helpers: markdownRemark (
            frontmatter: {
                lang: {
                    eq: $langKey
                }
                name: {
                    eq: "helpers"
                }
            }
        ) {
            frontmatter {
                trust_us
                last_news
                blog_path
                about_us_path
                request_demo_path
            }
        }
        image: file(relativePath: { eq: "background-blur.png" }) {
            sharp:childImageSharp {
                fluid {
                    srcSetWebp
                }
            }
        }
    }
`
